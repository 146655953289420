<template>
    <section class="main-container">
        <ObserverComponent classToToggle="fadeInTop" playOnce="true">
          <div class="info-section discovery-info-right">
                  <h2>Worldwide Delivery</h2>
                  <p>Experience the Superior Quality of Cuban Charcoal - The Best Choice for Grilling.</p>
              <div class="left-buttons">
<!--                  <SeeMoreButton @see-more="toggleLayout"/>-->
                  <ContactUsButton/>
              </div>
          </div>
        </ObserverComponent>
    </section>
</template>

<script>
// import SeeMoreButton from "@/components/SeeMoreButton.vue";
import ContactUsButton from "@/components/ContactUsButton.vue";
import ObserverComponent from "@/components/IntersectionObserver.vue";

export default {
    name: "DeliverySection",
    components: {
        ObserverComponent,
        ContactUsButton,

    },
    data() {
        return {
            showExpanded: false,
            isColumn: false,
        };
    },
}
</script>


<style scoped>
.main-container {
    height: 50vh;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.6)), url("../../assets/ship-container_sunset.jpg"); /* Dark overlay */
    background-blend-mode: multiply;
    background-size: cover;/* Ensure image covers the entire section */
    background-position: center;
    color: #f9f9f7;
}
.discovery-info-right{
    width:50vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left:70px;
}

.left-buttons{
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
}

p{
    line-height: 1.5;
}

h2 {
    font-size: 40px;
    text-align: left;
    margin: 0px;
}

p {
    font-size: 17px;
    text-align: left;
    line-height: 1.5;
}
/* Tablet responsiveness */
@media (max-width: 768px) {
    .discovery-info-right {
        width: 70vw; /* Adjust width for tablet */
        padding-left: 40px; /* Adjust padding for tablet */
    }

    h2 {
        font-size: 35px; /* Adjust font size for tablet */
    }

    p {
        font-size: 15px; /* Adjust font size for tablet */
    }

    .left-buttons{
        justify-content: center;
    }

}

/* Mobile responsiveness */
@media (max-width: 480px) {
    .main-container {
        justify-content: center; /* Center content for mobile */
    }

    .discovery-info-right {
        width: 90vw; /* Adjust width for mobile */
        padding-left: 20px; /* Adjust padding for mobile */
    }

    h2 {
        font-size: 30px; /* Further reduce font size for mobile */
        text-align: center; /* Center align text for mobile */
    }

    p {
        font-size: 13px; /* Further reduce font size for mobile */
        text-align: center; /* Center align text for mobile */
    }

    .left-buttons {
        justify-content: center; /* Center buttons for mobile */
    }
}

</style>