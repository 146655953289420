// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCxi4xvO1Z8McwT1tp9Ct5KiWWVZn_jB-s",
    authDomain: "charcoal-vue.firebaseapp.com",
    projectId: "charcoal-vue",
    storageBucket: "charcoal-vue.appspot.com",
    messagingSenderId: "199628936300",
    appId: "1:199628936300:web:906ae08fd7d2ef8a713449"
};

// init firebase
initializeApp(firebaseConfig)

// init firestore service
const db = getFirestore()
export default db