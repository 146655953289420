<template>
    <section class="main-container">

        <div class="info-section problem-info-right">
            <ObserverComponent classToToggle="fadeInTop" playOnce="true">
            <ul class="info-list">
                <li>
                    <font-awesome-icon :icon="'fa-solid fa-fire'" size="2x" style="color: goldenrod; margin-right: 20px;margin-bottom: 70px" />
                    <div>
                        <h3>Slow Combustion</h3>
                        <p>Marabu charcoal burns slower than others, saving time and money with less frequent addition during service.</p>
                    </div>
                </li>
                <li>
                    <font-awesome-icon :icon="'fa-solid fa-fire'" size="2x" style="color: goldenrod; margin-right: 20px;margin-bottom: 70px" />
                    <div>
                        <h3>No Spark. Low Ashes</h3>
                        <p>
                            Igniting Marabu charcoal emits few sparks and minimal dirt, enhancing kitchen safety and cleanliness with less ash than other charcoals..</p>
                    </div>
                </li>
                <li>
                    <font-awesome-icon :icon="'fa-solid fa-fire'" size="2x" style="color: goldenrod; margin-right: 20px;margin-bottom: 70px" />
                    <div>
                        <h3>Subtle Flavor</h3>
                        <p>The neutral charcoal's pleasant aroma enhances the roast's flavor exquisitely..</p>
                    </div>
                </li>
            </ul>
            <div class="left-buttons">
<!--                <SeeMoreButton @see-more="toggleLayout"/>-->
            </div>
            </ObserverComponent>
        </div>
        <ObserverComponent classToToggle="fadeInTop" playOnce="true">
            <div class="info-section problem-info-left">
                <div class="info-section problem-info-left">
                    <video width="100%" height="100%" autoplay muted loop playsinline style="width:100%;height:100%;position:absolute;left:0px;top:0px;overflow:hidden;">
                        <source src="https://afffiliate-bucket.nyc3.digitaloceanspaces.com/whatsup-documents/WhatsApp%20Video%202023-12-27%20at%2019.37.17.mp4" type="video/mp4">
                        Your browser does not support the video tag.
                    </video>
                </div>

            </div>

        </ObserverComponent>

    </section>
</template>

<script>
// import SeeMoreButton from "@/components/SeeMoreButton.vue";
import ObserverComponent from "@/components/IntersectionObserver.vue";

export default {
    name: "ProblemSection",
    components: {
        ObserverComponent,
         // SeeMoreButton

    },
    data() {
        return {
            showExpanded: false,
            isColumn: false,
        };
    },
}
</script>


<style scoped>
.main-container {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row-reverse;
    background: #131413;
    color: #f9f9f7;
}

.info-section{
    height: 80vh;
}

.problem-info-right{
    width: 40vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.problem-info-left {
    width: 40vw;
    box-sizing: border-box;
    position: relative;
    border-radius: 5px;/* Moved from inline style to here */
}

.left-buttons{
    margin-top: 20px;
}

.info-list {
    list-style-type: none; /* Removes default list styling */
    padding-left: 0; /* Removes default padding */
}

.info-list li {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.info-list li font-awesome-icon {
    margin-right: 10px; /* Adds spacing between icon and text */
    margin-bottom: 20px;
}

.info-list h3{
    color: whitesmoke;
}

p{
    line-height: 1.5;
}


/* Tablet responsiveness */
@media (max-width: 768px) {
    .main-container {
        height: fit-content;
        justify-content: center;
    }

    .problem-info-right, .problem-info-left {
        width: 80%; /* Adjust width for tablet */
        height: auto; /* Adjust height */
    }

    .problem-info-left{
        height: 30vh;
        width: 100%;
        display: none;
    }


}

/* Mobile responsiveness */
@media (max-width: 480px) {
    p{
        font-size: 15px;
    }

    .left-buttons{
        display: flex;
    }
}

</style>