<template>
    <div id="success-body">
        <div id="success-box">
            <h1>Message Received!</h1>
            <h3>We have successfully received your message and we'll be getting back to you shortly</h3>
            <i id="check" class="fa fa-check-circle" aria-hidden="true"></i>
            <button class="custom-btn btn-9" @click="closeModal">
                <span>Close</span>
            </button>
        </div>
    </div>
</template>


<script>
export default {
    name: "SuccessMessage",
    methods: {
        closeModal() {
            this.$emit('close'); // Emit an event named 'close'
        },
    },
}
</script>
<style>
/* Add the CSS styles here */

#success-box {
    max-width: 600px;
    margin: auto;
    width: 90vw;
    height: 40vh;
    padding: 5px;
    background-color: white;
    border-radius: 5px;
    animation: fadein 800ms, fly 800ms ease-out;
    animation-fill-mode: forwards;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    z-index: 10;
    position: fixed; /* Changed from absolute to fixed */
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Adjust the position to truly center it */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}



#success-box h1, #success-box h3 {
    display: block;
    margin: auto;
    width: 95%;
    margin-top: 20px;
    text-align: center;
}

#success-box h1 {
    color: black;
    font-weight: 300;
    font-size: 42px;
}

#success-box h3 {
    color: black;
}

#check {
    font-size: 60px;
    margin: 30px auto;
    color: #66BB6A;
    animation: grow 900ms;
    animation-fill-mode: forwards;
}

#success-box a {
    width: 150px;
    height: 50px;
    padding: 10px 25px;
    border: 2px solid black;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    color: black;
    margin-left: 10px;
}

#success-box a:hover {
    opacity: 0.9;
}

#success-box a:active {
    filter: brightness(0.9);
}

@keyframes fly {
    0% {transform: translateY(50vh);}
    30% {transform: translateY(-5vh);}
    60% {transform: translateY(3vh);}
    90% {transform: translateY(0vh);}
}

@keyframes grow {
    0% {transform: scale(0);}
    30% {transform: scale(0);}
    60% {transform: scale(1.3);}
    100% {transform: scale(1);}
}

.custom-btn {
    width: 150px;
    height: 50px;
    padding: 10px 25px;
    border: 2px solid black;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    color: black;
    margin-left: 10px;
}

.btn-9 {
    z-index: 2;
    transition: all 0.3s ease;
    overflow: hidden;
}

.btn-9:after {
    position: absolute;
    content: " ";
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease;
}

.btn-9:hover {
    box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.5), -4px -4px 6px 0 rgba(116, 125, 136, 0.2), inset -4px -4px 6px 0 rgba(255, 255, 255, 0.5), inset 4px 4px 6px 0 rgba(116, 125, 136, 0.3);
    color: white;
}

.btn-9:hover:after {
    transform: scale(2) rotate(180deg);
    background: black;
    box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.5), -4px -4px 6px 0 rgba(116, 125, 136, 0.2), inset -4px -4px 6px 0 rgba(255, 255, 255, 0.5), inset 4px 4px 6px 0 rgba(116, 125, 136, 0.3);
}
</style>
