<template>
    <button class="custom-btn btn-6" @click="scrollToSection('benefits')">
        <span>Learn More</span>
    </button>
</template>

<script>
export default {
    name: 'SeeServicesButton',
    methods: {
        scrollToSection(sectionId) {
            const section = document.getElementById(sectionId);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }
};
</script>

<style scoped>

.custom-btn {
    width: 150px;
    height: 50px;
    padding: 10px 25px;
    border: 2px solid #000;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    margin-right: 10px;
}

.btn-6 {
    background: #000;
    color: #fff;
    line-height: 50px;
    padding: 0;
    border: none;
}

.btn-6 span {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;

}

.btn-6:before,
.btn-6:after {
    position: absolute;
    content: "";
    height: 0%;
    width: 2px;
    background: #000;
}

.btn-6:before {
    right: 0;
    top: 0;
    transition: all 500ms ease;
}

.btn-6:after {
    left: 0;
    bottom: 0;
    transition: all 500ms ease;
}

.btn-6:hover {
    color: white;
    background: transparent;
    border-radius: 15px;
}

.btn-6:hover:before {
    transition: all 500ms ease;
    height: 100%;

}

.btn-6:hover:after {
    transition: all 500ms ease;
    height: 100%;
}

.btn-6 span:before,
.btn-6 span:after {
    position: absolute;
    content: "";
    background: white;
}

.btn-6 span:before {
    left: 0;
    top: 0;
    width: 0%;
    height: 2px;
    transition: all 500ms ease;
}

.btn-6 span:after {
    right: 0;
    bottom: 0;
    width: 0%;
    height: 2px;
    transition: all 500ms ease;
}

.btn-6 span:hover:before {
    width: 100%;
}

.btn-6 span:hover:after {
    width: 100%;
}
</style>