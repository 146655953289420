import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import VueCookieAcceptDecline from 'vue-cookie-accept-decline';
import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css';
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faFire } from '@fortawesome/free-solid-svg-icons'
import { faBurn } from '@fortawesome/free-solid-svg-icons' // Import faBurn
import { faSmog } from '@fortawesome/free-solid-svg-icons' // Import faSmog
import { faTree } from '@fortawesome/free-solid-svg-icons' // Import faTree
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { faClock } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faFire, faBurn, faSmog, faTree, faCheck,faClock)
createApp(App)
    .component('font-awesome-icon', FontAwesomeIcon)
    .component('vue-cookie-accept-decline', VueCookieAcceptDecline)
    .use(router)
    .mount('#app')
