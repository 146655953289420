<template>
    <button class="custom-btn btn-9" @click="toggleText">
        <span>{{ buttonText }}</span>
    </button>
</template>

<script>
export default {
    name: 'SeeMoreButton',
    data() {
        return {
            isExpanded: false,
        };
    },
    computed: {
        buttonText() {
            return this.isExpanded ? 'See Less' : 'See More';
        }
    },
    methods: {
        toggleText() {
            this.isExpanded = !this.isExpanded;
            this.$emit('see-more');
        },
    },
};
</script>

<style scoped>
.custom-btn {
    width: 150px;
    height: 50px;
    padding: 10px 25px;
    border: 2px solid #f9f9f7;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    color: #f9f9f7;
    margin-left: 10px;
}

.btn-9 {
    z-index: 2;
    transition: all 0.3s ease;
    overflow: hidden;
}

.btn-9:after {
    position: absolute;
    content: " ";
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease;
}

.btn-9:hover {
    box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.5), -4px -4px 6px 0 rgba(116, 125, 136, 0.2), inset -4px -4px 6px 0 rgba(255, 255, 255, 0.5), inset 4px 4px 6px 0 rgba(116, 125, 136, 0.3);
    color: black;
}

.btn-9:hover:after {
    transform: scale(2) rotate(180deg);
    background: #f9f9f7;
    box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.5), -4px -4px 6px 0 rgba(116, 125, 136, 0.2), inset -4px -4px 6px 0 rgba(255, 255, 255, 0.5), inset 4px 4px 6px 0 rgba(116, 125, 136, 0.3);
}
</style>
