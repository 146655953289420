<template>
    <section class="main-container">
        <ObserverComponent classToToggle="fadeInTop" playOnce="true" >
        <h2>Our Charcoal Specifications</h2>
        </ObserverComponent>
            <div class="info-section problem-info-left">
                <ResponsiveTable />
            </div>
    </section>
</template>

<script>
// import SeeMoreButton from "@/components/SeeMoreButton.vue";

import ResponsiveTable from "@/components/welcome_sections/ResponsiveTable.vue";
import ObserverComponent from "@/components/IntersectionObserver.vue";


export default {
    name: "DetailsSection",
    components: {
        ObserverComponent,
        ResponsiveTable

    },
    data() {
        return {

        };
    },
}
</script>


<style scoped>
.main-container {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    background: #54595f;
    color: #f9f9f7;
    padding-bottom: 40px;
}

h2{
    font-size: 30px;
}
.info-section{
    height: 90vh;
    width: 40vw;
    /*border: 1px solid white;*/
}


.problem-info-left {
    width: 50vw;
    box-sizing: border-box;
    position: relative;
    border-radius: 5px;/* Moved from inline style to here */
}


.info-list li {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.info-list li font-awesome-icon {
    margin-right: 10px; /* Adds spacing between icon and text */
    margin-bottom: 20px;
}

.info-list h3{
    color: whitesmoke;
}

p{
    line-height: 1.5;
}


/* Tablet responsiveness */
@media (max-width: 768px) {
    .main-container {
        height: fit-content;
        justify-content: center;
    }

    .problem-info-right, .problem-info-left {
        width: 80%; /* Adjust width for tablet */
        height: auto; /* Adjust height */
    }



}

/* Mobile responsiveness */
@media (max-width: 480px) {
    p{
        font-size: 15px;
    }

    .left-buttons{
        display: flex;
    }
}

</style>