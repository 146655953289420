<template>
    <section class="main-container" >
        <ObserverComponent classToToggle="fadeInTop" playOnce="true">
        <div class="benefit-info">
            <p style="font-weight: 500;color: goldenrod">Premium</p>
            <h2>Superior Charcoal. Superior Reputation</h2>
            <p :class="{'hide': isColumn}">Cuban charcoal is a superior alternative made from exotic Marabu wood using an eco-friendly process.
                Unlike cheap charcoal that burns unevenly, oversmokes food, loses heat quickly, and results in unsatisfied customers and lost business.
                Carbon Cuban charcoal provides consistent high performance and represents an unmatched standard in high-quality, sustainable charcoal.</p>

            <div class="benefit-info-container" :class="{'column-layout': isColumn}">
                <div v-for="benefit in benefits" :key="benefit.title" class="benefit-info-list">
                    <font-awesome-icon :icon="`fa-solid ${benefit.icon}`" size="2x" style="color: goldenrod" />
                    <h3>{{ benefit.title }}</h3>
                    <p :style="{ fontSize: isColumn ? '15px' : '17px' }">{{ benefit.content }}</p>
                    <p :style="{ fontSize: isColumn ? '15px' : '17px' }" v-show="benefit.showExpanded">{{ benefit.expanded_content }}</p>
                </div>
            </div>

            <div class="benefit-buttons">
                <SeeMoreButton @see-more="toggleLayout"/>
            </div>
        </div>
        </ObserverComponent>
    </section>
</template>


<script>
import SeeMoreButton from "@/components/SeeMoreButton.vue";
import ObserverComponent from "@/components/IntersectionObserver.vue";

export default {
    name: "BenefitsSection",
    components: {
        ObserverComponent,
        SeeMoreButton
    },
    data() {
        return {
            showExpanded: false,
            isColumn: false,
            benefits: [
                {
                    title: "3X Longer Burn Times",
                    content: "No more constantly buying bags. Carbon stays lit for hours.",
                    expanded_content:"While cheaper charcoal briquettes burn out in 30-40 minutes, a single Carbon Cuba charcoal load can sustain heat for up to 2 hours. " +
                        "This is thanks to densely-packed carbon and a low moisture content below 3% - allowing for an ultra efficient burn." +
                        " Customers can cook low and slow without having to constantly replace spent charcoal.",
                    icon: "fa-clock"
                },
                {
                    title: "16-25% More Energy",
                    content: "Reach and maintain high heat for flawless sear marks.",
                    expanded_content:"With a higher carbon density and calorific value exceeding 8,000 BTU per pound, Carbon charcoal packs more usable energy per kilogram. " +
                        "The consistent heat output allows precise temperature control for perfect grilling. No more cold spots or burnt portions from uneven heating",
                    icon: "fa-burn" // Replace with an appropriate icon name
                },
                {
                    title: "Significantly Less Smoke",
                    content: "Minimized smoke means real food flavor shines through.",
                    expanded_content:"Made from a single wood species, Carbon has very little residual content compared to composite charcoals filled with bark, " +
                        "lignin and binding fillers. " +
                        "This results in minimal smoke production - allowing true wood-fire flavor to shine through.",
                    icon: "fa-smog" // Replace with an appropriate icon name
                },
                {
                    title: "Carbon-Neutral Fuel",
                    content: "Neutralizes its own CO2 emissions through sustainable forestry.",
                    expanded_content:"The exotic Marabu tree is an invasive pest in Cuba. " +
                        "Carbon production helps control its spread while rejuvenating native forests and farmland. " +
                        "The sustainable model offsets all CO2 released from burning, making this a carbon-neutral fuel.",
                    icon: "fa-tree" // Replace with an appropriate icon name
                }
            ]
        };
    },
    methods: {
        toggleLayout() {
            this.isColumn = !this.isColumn; // Toggle the layout
            // Toggle the expanded content for all benefits
            this.benefits.forEach(benefit => {
                benefit.showExpanded = this.isColumn;
            });
        },

    },
}
</script>


<style scoped>
.main-container {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #54595f;
    color: #f9f9f7;
}

.benefit-info {
    height: 90vh;
    width: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    z-index: 10;
}

.benefit-info-container{
    display: flex;
    width: 85vw;
    justify-content: space-between;
    flex-wrap: wrap; /* Add wrap to allow items to wrap when column */
    transition: all 0.3s ease;
}
.benefit-info-list{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 23%;
    height: 200px;
    margin-bottom: 50px;
    margin-top: 30px;
    border-radius: 6px;
}

.benefit-info-list *{
    margin-top: 10px;
}

@keyframes gradient-animation {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
}

.benefit-info h2 {
    font-size: 50px;
    text-align: center;
    margin: 0px;
    color: #f9f9f7;
}

.benefit-info p {
    font-size: 17px;
    text-align: center;
    line-height: 1.5;
}


.benefit-buttons {
    margin-top: 15px;
}

.hide{
    display: none;
}

/* Tablet responsiveness */
@media (max-width: 768px) {
    .benefit-info {
        width: 80%;
        height: auto;
    }

    .benefit-info h2 {
        font-size: 35px; /* Adjusted for tablet */
    }

    .benefit-info p {
        font-size: 15px; /* Adjusted for tablet */
    }

    .benefit-info-container {
        flex-direction: column; /* Stack benefits vertically on tablet */
        align-items: center;
    }

    .benefit-info-list {
        width: 70%; /* Adjust width for tablet view */
        height: auto; /* Adjust height for content */
    }

    .benefit-info-list{
         margin-bottom: 0px;
         margin-top: 0px;
    }

    .benefit-info h2{
        font-size: 20px
    }
    .benefit-info-list *{
        margin: 5px;
    }

    .benefit-info-container{
        margin-top: 10px;
        margin-bottom: 20px;
    }

    p {
        width: 70%;
    }

    h2{
        width: 80%;
    }

    .benefit-info{
        width: 100%;
    }


}

/* Mobile responsiveness */
@media (max-width: 480px) {
    .main-container{
        height: fit-content;
    }
    .benefit-info p {
        font-size: 13px; /* Further reduced for mobile */
    }

    .benefit-info-list {
        width: 90%; /* Adjust width for mobile view */
    }
}
</style>