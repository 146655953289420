<template>
    <footer class="hero">
        <div class="container flex">
            <div class="container__about">
                <h2>About</h2>
                <p>This is a dedicated platform for Cuban Marabu Charcoal, a premier offering by Jonex Solutions Corp.
                    We specialize in providing Cuban Marabu Charcoal to leading Canadian retailers and other esteemed companies.
                    Our focus is on delivering a product that exemplifies quality, sustainability, and superior customer service,
                    aligning with the high standards expected by Canadian businesses and their customers.</p>
                <div class="container__recentpages">
                    <h2>Info</h2>
                    <ul>
                        <li><a href="tel:+19059943664">905 994-3664</a></li>
                        <li><a href="mailto:info@jonex.ca">info@jonex.ca</a></li>
                    </ul>
                </div>
            </div>
                <div class="container__more">
                    <h2>Navigation</h2>
                    <ul>
                        <li><a href="javascript:void(0)" @click="scrollToSection('hero')">Home</a></li>
                        <li><a href="javascript:void(0)" @click="scrollToSection('benefits')">Benefits</a></li>
                        <li><a href="javascript:void(0)" @click="scrollToSection('problem')">Solutions</a></li>
                        <li><a href="javascript:void(0)" @click="scrollToSection('discovery')">Discovery</a></li>
                        <li><a href="javascript:void(0)" @click="scrollToSection('delivery')">Delivery</a></li>
                    </ul>
                </div>

        </div>
        <div class="line__separete"></div>
        <div class="by flex">
            <p>Copyright © 2023 All Rights Reserved by Jonex Solutions.</p>
        </div>
    </footer>
</template>

<script>
// import ObserverComponent from "@/components/IntersectionObserver.vue";

export default {
    name: 'FooterComponent',
    components: {

    },
    methods: {
        scrollToSection(sectionId) {
            const section = document.getElementById(sectionId);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }
}
</script>

<style scoped>
/* Include your CSS styles here */

.hero {
    height: fit-content;
    background: #131413;
    background-size: cover;
    background-blend-mode: multiply;
    color: rgb(255, 255, 255);
    padding: 30px 80px 30px 70px;
}
.hero .container{
    justify-content: space-between;
    flex-wrap: wrap;
    flex: 1;
}
.flex{
    display: flex;
}
.container__about{
    margin-bottom: 10px;
}

.container__about p{
    margin-top: 10px;
    max-width: 600px;
    color: rgb(255, 255, 255);
    font-size: 17px;
    text-align: left;
    line-height: 1.5;
}

.container-pages{
    column-gap: 30px;
}

ul{
    list-style: none;
    line-height: 24px;
    padding-left: 5px;
}
ul a{
    color: inherit;
    text-decoration: none;
    color: rgb(255, 255, 255);
    font-size: 14px;
    text-align: left;
    text-align: left; /* Ensure left alignment */
}
.container-pages ul a:hover{
    font-weight: bold;
}
footer .by {
    margin-top: 30px;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

p{
    font-size: 17px;
    line-height: 1.5;
}
.line__separete{
    margin-top: 30px;
    width: 100%;
    height: 2px;
    background-color: #fff;
    z-index: 999;
}
.icons {
    margin-top: 20px;
    display: flex;
    column-gap: 25px;

}

.icon1 {
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-size: 20px;
    color: #fff;
    border-radius: 50%;
    outline: 2px solid #fff;
    transition-property:
            outline-offset, outline-color,
            background-color;
    transition-duration: .25s;
}

.icon1:hover {
    outline-offset: 4px;
}

.icon1:hover i {
    animation: shake .25s;
}

.icon--instagram:hover {
    background-image: radial-gradient(circle at 30% 107%,
    #fdf497 0%, #fdf497 5%,
    #fd5949 45%, #d6249f 60%,
    #285AEB 90%);
    outline-color: #a02d76;
}

.icon--twitter:hover {
    background-color: #1da1f2;
    outline-color: #1da1f2;
}

.icon--linkedin:hover {
    background-color: #0077b5;
    outline-color: #0077b5;
}

.icon--github:hover {
    background-color: #000000;
    outline-color: #000000;
}

@keyframes shake {
    10% {
        transform: rotate(15deg);
    }

    20% {
        transform: rotate(-15deg);
    }

    30% {
        transform: rotate(15deg);
    }

    40% {
        transform: rotate(-15deg);
    }

}

@media (max-width: 1100px) {
  .hero{
      height: fit-content;
      padding: 0;
  }
    .hero .container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .hero .container__about h2 {
        text-align: center;
    }

    footer .by p{
        text-align: center;
    }

    .container__recentpages ul{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .container__about{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .container__about p{
        font-size: 15px;
        width: 60%;
        text-align: center;
    }


}

/* Rest of the CSS */
</style>
