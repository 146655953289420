<template>
    <main id="main" class="container">
        <div class="row">
            <div class="col-xs-12 col-lg-offset-3 col-lg-6">
                <div class="text-center section-header">
                    <h1 id="title">Contact Form</h1>
                    <p id="description" class="description text-center">Let us know about you and what you are interested on</p>
                </div>
                <form method="GET" @submit.prevent="sendEmail" id="survey-form" name="survey-form" ref="myForm">

                <!-- First Name -->
                    <fieldset>
                        <label for="first-name" id="first-name-label">
                            First Name *
                            <input type="text" id="first-name" name="first-name" placeholder="Enter your first name (required)" required />
                        </label>
                    </fieldset>
                    <!-- Last Name -->
                    <fieldset>
                        <label for="last-name" id="last-name-label">
                            Last Name *
                            <input type="text" id="last-name" name="last-name" placeholder="Enter your last name"  />
                        </label>
                    </fieldset>
                    <!-- Company Name -->
                    <fieldset>
                        <label for="company-name" id="company-name-label">
                            Company Name *
                            <input type="text" id="company-name" name="company-name" placeholder="Enter your company name (required)" required />
                        </label>
                    </fieldset>
                    <!-- Email Address -->
                    <fieldset>
                        <label for="email" id="email-label">
                            Email Address *
                            <input type="email" id="email" name="email" placeholder="Enter your email address (required)" required />
                        </label>
                    </fieldset>
                    <!-- Phone Number -->
                    <fieldset>
                        <label for="phone-number" id="phone-number-label">
                            Phone Number *
                            <input type="tel" id="phone-number" name="phone-number" placeholder="Enter your phone number" />
                        </label>
                    </fieldset>
                    <!-- Desired Quantity Per Month -->
                    <fieldset>
                        <label for="desired-quantity">
                            Desired quantity per month (18 -20 tonnes per container): *
                            <select id="desired-quantity" name="desired-quantity" class="m-t-xs">
                                <option value="1-5-containers">1-5 containers</option>
                                <option value="6-9-containers">6-9 containers</option>
                                <option value="10-plus-containers">10+ containers</option>
                            </select>
                        </label>
                    </fieldset>
                    <!-- Packaging Type -->
                    <fieldset>
                        <label for="packaging-type">
                            Please select the type of packaging: *
                            <select id="packaging-type" name="packaging-type" class="m-t-xs">
                                <option value="20kg-poly-bags">20Kgs Polypropylene Bags</option>
                                <option value="20kg-kraft-bags">20Kgs Kraft Paper Bags</option>
                            </select>
                        </label>
                    </fieldset>
                    <!-- Custom Printed Packaging -->
                    <fieldset>
                        <label for="custom-packaging">
                            Would you like custom printed packaging? *
                            <select id="custom-packaging" name="custom-packaging" class="m-t-xs">
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                                <option value="maybe">Maybe</option>
                            </select>
                        </label>
                    </fieldset>

                    <!-- Submit Button -->
                    <button id="submit" type="submit" class="btn">Submit the form</button>
                </form>

                <div class="copyright m-t-sm">

                </div>
            </div>
        </div>
        <SuccessMessage v-if="formSubmitted" @close="formSubmitted = false" />

    </main>
</template>

<script>
import emailjs from "@emailjs/browser";
import SuccessMessage from "@/components/welcome_sections/SuccessMessage.vue";

export default {
    name: "ContactFormComponent",
    components: {SuccessMessage},
    data() {
        return {
            formSubmitted: false, // Track form submission state
        };
    },
    methods: {
        sendEmail() {
            emailjs.sendForm('service_v98lvdp', 'template_wjdk3cr', this.$refs.myForm, 'NxLLnhlEW3KDj2zPO')
                .then((result) => {
                    console.log('SUCCESS!', result.text);
                    this.formSubmitted = true;
                }, (error) => {
                    console.log('FAILED...', error.text);
                });
        }
    }
}
</script>

<style scoped lang="scss">
// Var
$font:          'Roboto', sans-serif;
$bg:            #131413;
$white:         #FFF;
$grey:          #333;
$rose:          #E87E91;
$blue-light:    #73bec8;
$blue-lighter:  #ABDAD3;
$green:         #85FFC7;
$red:           #EF5350;
$golden:        #daa520;

// Margin & Padding
.m-b-xs {
  margin-bottom: 2%;
}
.m-b-md {
  margin-bottom: 10%;
}
.m-t-xs {
  margin-top: 2%;
}
.m-t-sm {
  margin-top: 5%;
}

.section-header{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.container{
    padding-top: 50px;
    width: 70%;
    position: relative;
}

h1 {
    font-size: 40px;
    text-align: left;
    margin: 0px;
    color: whitesmoke;
}

p {
  color: $white;
}

a {
  color: $white;

  &:hover {
    text-decoration: none;
    color: $white;
  }
}

#survey-form {
  label,
  .labels {
    display: block;
    margin-bottom: 2%;
    font-family: $font;
    font-size: 1.1em;
    font-weight: 300;
    color: $white;
    letter-spacing: 0.5px;
  }

  input::-webkit-input-placeholder {
    color: transparent !important;
  }
  input::-moz-placeholder {
    color: transparent !important;
  }
  input:-ms-input-placeholder {
    color: transparent !important;
  }
  input:-moz-placeholder {
    color: transparent !important;
  }

  input,
  select {
    display: block;
    width: 100%;
    overflow: hidden;
    outline: none;
    border: 2px solid $grey;
  }

  input {
    margin-top: 1.5%;
    padding: 0 0 5px 0;
    background: transparent;
    border: none;
    outline: none;
    border-bottom: 2px solid $white;
    font-size: 1.1em;
    font-weight: 300;
    color: $white;

    &:focus {
      border-color: $golden;
    }
  }

  [type="checkbox"],
  [type="radio"] {
    display: inline-block;
    width: auto;
    margin: 0px 10px 0 0;
  }

  [type="checkbox"],
  [type="radio"]{
    display: inline-block;
    margin: 0 10px 0 0 !important;
    position: relative;
    top: 5px;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    height: 23px;
    width: 23px;
    transition: all 0.15s ease-out 0s;
    background: $bg;
    color: $white;
    cursor: pointer;
    outline: none;
    z-index: 1000;
    border: 1px solid #FFF;

    &:hover {
      border-color: $white;
    }
    &:checked {
      &:before {
        display: inline-block;
        height: 21px;
        width: 21px;
        position: relative;
        left: 0;
        bottom: 0;
        content: "\e014";
        text-align: center;
        font-family: 'Glyphicons Halflings';
        line-height: 20px;
        font-size: 15px;
        color: $golden;
      }
    }
    &:focus {
      outline: none;
      border-color: $white;
    }
  }

  [type="radio"] {
    border-radius: 50%;

    &:after {
      border-radius: 50%;
    }
  }

  [type=number] {
    width: 45px;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }
  }

  select {
    height: 40px;
    padding-left: 5px;
    background-color: $bg;
    border: 2px solid $white;
    color: $golden;

    & option {
      padding: 5px 10px;
      font-weight: 300;
      cursor: pointer;

      &:hover {
        background-color: $golden;
      }
    }
  }

  textarea {
    resize: none;
    margin-top: 2%;
    padding: 10px 10px 0px 20px;
    width: 100%;
    height: 90px;
    color: $golden;
    background-color: $bg;
    border: 2px solid $white;
  }

  .btn {
    display: inline-block;
    position: relative;
    width: 100%;
    margin: 3% 0 0 0;
    height: 45px;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    border: 3px solid $white;
    border-radius: 0;
    font-weight: 500;
    font-size: 1.2em;
    color: $white;
    text-align: center;
    background: none;
    transition: color 0.25s ease;

    &:after {
      position: absolute;
      content: '';
      top:0;
      left: 0;
      width: 0;
      height: 100%;
      background-color: $white;
      transform-origin:left;
      transition: width 0.5s ease;
      z-index:-1;
    }
    &:hover {
      color: $white;
        background-color: $bg;

      &:after {
        width: 100%;
      }
    }
  }

  fieldset {
    margin: 5% 0 0 0;
  }
}


.copyright {
  text-align: center;
  font-size: 10px;
  color: $white;

  & i {
    color: $red;
  }
}

// Responsive
@media only screen and (max-width: 600px) {
    body {
        padding: 8% 0;
    }
}
</style>