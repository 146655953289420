<template>
    <div class="content">
        <a id="menu-button" @click="toggleMenu"></a>
        <div id="hamburger-menu" ref="menu">
            <nav>
                <router-link to="/" @click="toggleMenu">Home</router-link>
                <router-link to="/gallery" @click="toggleMenu">Gallery</router-link>
            </nav>
        </div>
        <div id="overlay" @click="closeMenu" :class="{ open: menuOpen }" ref="overlay"></div>
    </div>
</template>


<script>
export default {
    name: "MenuComponent",
    data() {
        return {
            menuOpen: false,
            width: 200,
            height: 44 * 4 + 20,
            speed: 300
        };
    },
    methods: {
        toggleMenu() {
            this.menuOpen = !this.menuOpen;
            if (this.menuOpen) {
                this.animateMenu('open');
            } else {
                this.animateMenu('close');
            }
        },
        closeMenu() {
            if (this.menuOpen) {
                this.animateMenu('close');
            }
        },
        animateMenu(menuToggle) {
            if (menuToggle === 'open') {
                this.$refs.overlay.style.opacity = 1;
                this.$refs.menu.style.width = this.width + 'px';
                this.$refs.menu.style.height = this.height + 'px';
            }
            if (menuToggle === 'close') {
                this.$refs.overlay.style.opacity = 0;
                this.menuOpen = false;
                this.$refs.menu.style.width = '0px';
                this.$refs.menu.style.height = '0px';
            }
        }
    }
}
</script>

<style scoped>
nav{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
#menu-button {
    position: fixed;
    top: 20px;
    left: 20px;
    display: block;
    width: 50px;
    height: 50px;
    background: #111;
    border-radius: 5px;
    z-index: 200;
}
#menu-button:after {
    position: absolute;
    content: "☰";
    font-size: 42px;
    color: #FFF;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
}
#menu-button:hover {
    opacity: .8;
}
#menu-button.on:after {
    content: "x";
}

#overlay {
    display: none;
    height: 100vh;
    width: 100%;
    position: fixed;
    z-index: 99;
    opacity: 0;
    background-color: rgba(0,0,0,0.4);
    bottom: 0;
    right: 0;
    left: 0;
}
#overlay.open {
    display: block;
}

#hamburger-menu {
    height: 0;
    width: 0;
    background: #111;
    position: fixed;
    top: 20px;
    left: 90px;
    z-index: 101;
    overflow: hidden;
    border-radius: 5px;
}
#hamburger-menu nav {
    padding: 10px;
    z-index: 101;
    overflow-y: hidden;
    overflow-x: hidden;
}
#hamburger-menu nav a {
    display: block;
    padding: 10px;
    height: 1em;
    color: #CCC;
    line-height: 1em;
    text-decoration: none;
    overflow: hidden;
    font-size: 30px;
}
#hamburger-menu nav a:hover {
    cursor: pointer;
    color: #FFF;
    background: rgba(255,255,255,0.02);
}
</style>
