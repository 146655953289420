<template>
    <section class="main-container" >
        <div class="discovery-info info-left">
            <ObserverComponent classToToggle="fadeInTop" playOnce="true">
             <div class="content">
                <p style="font-weight: 500;color: goldenrod;font-size: 15px">Premium</p>
                <h2>Discover the Power of Marabu Cuban Charcoal</h2>
                <p style="width:80%;">
                    Sourced from renewable Marabu forests and produced sustainably by Cuban farmers, our premium wood charcoal is
                    carefully crafted to deliver consistent heat and long-lasting cooking performance.
                </p>

                <div class="benefit-info-container" :class="{'column-layout': isColumn}">
                    <div v-for="benefit in benefits" :key="benefit.title" claXss="benefit-info-list">
                        <h3>{{ benefit.title }}</h3>
                        <p style="font-size: 15px">{{ benefit.content }}</p>
                        <p style="font-size: 15px">{{ benefit.expanded_content }}</p>
                    </div>
                </div>

                <div class="hero-buttons">
<!--                    <SeeMoreButton @see-more="toggleLayout"/>-->
                </div>
            </div>
            </ObserverComponent>
        </div>
        <div class="discovery-info info-right ">
            <ObserverComponent classToToggle="fadeInTop" playOnce="true" >
              <div class="info-point-top">
                <figure class="snip1200" @mouseleave="removeHover" :class="{ hover: isHovering }" @click="toggleClick($event)" >
                    <img src="../../assets/supirior-quality-3.jpeg" alt="superior quality image" style="height: 100%;width: 100%"/>
                    <figcaption>
                        <p>We carefully manufacture our premium charcoal to provide consistent heat and performance.</p>
                        <div class="heading">
                            <h3><span>Superior Quality</span></h3>
                        </div>
                    </figcaption>
                    <a href="#"></a>
                </figure>
                <figure class="snip1200" @mouseleave="removeHover" :class="{ hover: isHovering }" @click="toggleClick($event)" >
                    <img src="../../assets/marabu.jpeg" alt="superior quality image" style="height: 100%;width: 100%">
                    <figcaption>
                        <p>We source our charcoal from responsibly managed forests, ensuring minimal environmental impact.</p>
                        <div class="heading">
                            <h3><span>Sustainable Sourcing</span></h3>
                        </div>
                    </figcaption>
                    <a href="#"></a>
                </figure>
            </div>
            </ObserverComponent>
            <ObserverComponent classToToggle="fadeInTop" playOnce="true">
            <div class="info-point-bottom">
                <figure class="snip1200" @mouseleave="removeHover" :class="{ hover: isHovering }"  @click="toggleClick($event)" >
                    <img src="../../assets/spread-marabu.jpeg" alt="superior quality image" style="height: 100%;width: 100%">
                    <figcaption>
                        <p>Marabu tree is an invasive pest in Cuba. Our carbon production allows forest ecosystems stay healthy.</p>
                        <div class="heading">
                            <h3><span>Sustainable Forestry</span></h3>
                        </div>
                    </figcaption>
                    <a href="#"></a>
                </figure>
                <figure class="snip1200" @mouseleave="removeHover" :class="{ hover: isHovering }"  @click="toggleClick($event)" >
                    <img src="../../assets/sacos.jpeg" alt="superior quality image" style="height: 100%;width: 100%">
                    <figcaption>
                        <p>"We provide a variety of packaging solutions for our Marabu charcoal, including eco-friendly paper bags and durable polyethylene options, catering to diverse customer preferences and requirements</p>
                        <div class="heading">
                            <h3><span>Packaging Variety</span></h3>
                        </div>
                    </figcaption>
                    <a href="#"></a>
                </figure>
            </div>
            </ObserverComponent>
        </div>
    </section>
</template>


<script>
// import SeeMoreButton from "@/components/SeeMoreButton.vue";
import ObserverComponent from "@/components/IntersectionObserver.vue";

export default {
    name: "BenefitsSection",
    components: {
        ObserverComponent,
        // SeeMoreButton
    },
    data() {
        return {
            showExpanded: false,
            isColumn: false,
            isHovering: false,
        };
    },
    methods: {
        toggleClick: function(event) {
            event.preventDefault(); // Prevent the default action
            event.currentTarget.classList.toggle('clicked');
        }
    }


}
</script>


<style scoped>
.main-container {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #131413;
    color: #f9f9f7;

}

.discovery-info {
    height: 90vh;
    display: flex;
    z-index: 10;
    /*temporary*/
}

.info-left{
    width: 40%;
    padding-right: 60px;
    padding-left:70px;
}

.info-right{
    width: 60%;
    display: flex;
    flex-direction: column;
    padding-right:70px;
}

.info-point-top {
    width: 100%;
    height: 45vh;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.info-point-bottom {
    width: 100%;
    height: 45vh;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.info-point{
    width: 45%;
    height: 100%;
    border-bottom: 12px;
    display: flex;
    flex-direction: column;

}

.info-point img{
    width: 100%;
    min-height: 32vh;
    background-size: cover; /* Ensure image covers the entire section */
    background-repeat: no-repeat; /* Prevent image from repeating */
    border-radius: 5px;

}

.discovery-info-list *{
    margin-top: 10px;
}

@keyframes gradient-animation {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
}

.discovery-info h2 {
    font-size: 40px;
    margin: 0px;
    color: #f9f9f7;
    width:70%;
    text-align: left
}

.discovery-info p {
    font-size: 17px;
    text-align: left;
    line-height: 1.5;
}

.point-text h3{
    font-size: 17px;
    margin: 15px 0 0;
   /*border: 1px solid goldenrod;*/
}

.point-text h3::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -5px; /* Adjust this value to control the separation */
    height: 1px; /* Adjust for underline thickness */
}

.point-text p{
    font-size: 15px;
    margin: 10px 0 0;
}

@import url(https://fonts.googleapis.com/css?family=Raleway:400,500,800);

figure.snip1200 {
    font-family: 'Raleway', Arial, sans-serif;
    position: relative;
    overflow: hidden;
    margin: 10px;
    min-width: 220px;
    max-width: 310px;
    max-height: 310px;
    width: 100%;
    background: #000000;
    color: #ffffff;
    text-align: center;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    font-size: 16px;
    border-radius: 5px;
}
figure.snip1200 * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.45s ease-in-out;
    transition: all 0.45s ease-in-out;
}
figure.snip1200 img {
    max-width: 100%;
    position: relative;
    opacity: 0.7;
}
figure.snip1200 figcaption {
    position: absolute;
    top: 45%;
    left: 7%;
    right: 7%;
    bottom: 45%;
    border: 1px solid white;
    border-width: 1px 1px 0;
}
figure.snip1200 .heading {
    overflow: hidden;
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
    position: absolute;
    bottom: 0;
    width: 100%;
}
figure.snip1200 h3 {
    display: table;
    margin: 0 auto;
    padding: 0 10px;
    position: relative;
    text-align: center;
    width: auto;
    text-transform: uppercase;
    font-weight: 400;
}
figure.snip1200 h3 span {
    font-weight: 800;
}
figure.snip1200 h2:before,
figure.snip1200 h2:after {
    position: absolute;
    display: block;
    width: 1000%;
    height: 1px;
    content: '';
    background: white;
    top: 50%;
}
figure.snip1200 h3:before {
    left: -1000%;
}
figure.snip1200 h3:after {
    right: -1000%;
}
figure.snip1200 p {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    width: 100%;
    padding: 0 20px;
    margin: 0;
    opacity: 0;
    line-height: 1.6em;
    font-size: 0.9em;
}
figure.snip1200 a {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    z-index: 1;
}
figure.snip1200:hover img,
figure.snip1200.hover img {
    opacity: 0.25;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
figure.snip1200:hover figcaption,
figure.snip1200.hover figcaption {
    top: 7%;
    bottom: 7%;
}
figure.snip1200:hover p,
figure.snip1200.hover p {
    opacity: 1;
    -webkit-transition-delay: 0.35s;
    transition-delay: 0.35s;
}

/* Tablet responsiveness */
@media (max-width: 768px) {
    .main-container {
        flex-direction: column;
        align-items: center;
        overflow-x: hidden;
    }

    .info-left, .info-right {
        width: 80%; /* Adjust width for tablet */
        padding: 0 30px; /* Adjust padding for tablet */
    }

    .info-point-top, .info-point-bottom {
        flex-direction: column; /* Stack images vertically */
        height: auto;
    }

    .info-point {
        width: 100%; /* Full width for each point */
        margin-bottom: 20px;
    }

    .discovery-info {
        height: 60vh;
    }

    .main-container {
        height: fit-content;
    }

    figure.snip1200{
        max-width: 95%;
    }

    .discovery-info {
        height: fit-content;
    }

    .discovery-info h2  {
        width: 95%;
        font-size: 30px;
        text-align: center;
    }

    .discovery-info p{
        width: 95%;
        text-align: center;
    }

    .discovery-info .content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
    }

    figure.snip1200.clicked img {
        opacity: 0.25;
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }

    figure.snip1200.clicked figcaption {
        top: 7%;
        bottom: 7%;
    }

    figure.snip1200.clicked p {
        opacity: 1;
        -webkit-transition-delay: 0.35s;
        transition-delay: 0.35s;
    }

}

/* Mobile responsiveness */
@media (max-width: 480px) {
    .main-container {
        flex-direction: column;
        align-items: center;
        overflow-x: hidden; /* Prevents horizontal scrolling */
    }
    .info-left, .info-right {
        width: 95%; /* Adjust width for mobile */
        padding: 0 15px; /* Adjust padding for mobile */
    }

    .discovery-info h2, .discovery-info p {
        text-align: center; /* Center align text for mobile */
    }

    .benefit-info-container, .benefit-info-list {
        width: 100%; /* Full width for benefits */
    }

}


</style>