<template>
    <section class="main-container" >

            <ContactFormComponent />

    </section>
</template>


<script>

import ContactFormComponent from "@/components/welcome_sections/ContactFormComponent.vue";

export default {
    name: "FormSection",
    components: {
        ContactFormComponent,

    },

}
</script>


<style scoped>
.main-container {
    height: fit-content;
    width: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #54595f;
    color: #f9f9f7;
}


</style>