<template>
 <router-view />
</template>

<script>


export default {
  name: 'App',

}
</script>

<style>
 body{
   margin: 0px;
  font-family: 'Lato', sans-serif;
 }
 section{
  padding-bottom: 20px;
 }

</style>
