<template>
        <!-- Button with reverse arrow -->
        <button class="btn btn-reverse btn-arrow">
      <span>Contact Us
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.1 25.8">
          <g>
            <line fill="none" stroke="#FFFFFF" stroke-width="3" x1="0" y1="12.9" x2="34" y2="12.9"></line>
            <polyline fill="none" stroke="#FFFFFF" stroke-width="3" points="22.2,1.1 34,12.9 22.2,24.7"></polyline>
          </g>
        </svg>
      </span>
        </button>
</template>

<script>
export default {
    name: 'ContactUsButton'
    // Add any component logic here
}
</script>

<style scoped>

/* Button Styles */
.btn,
button {
    width: 150px;
    height: 50px;
    padding: 10px 25px;
    border: 2px solid #f9f9f7;
    font-family: 'Lato', sans-serif;
    font-weight: 500;

    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    color: #f9f9f7;
    /*margin-left: 20px;*/
    margin-bottom: 2px;
    /* Additional styles for arrow buttons */
    text-transform: capitalize;
    /*letter-spacing: 1.5px;*/
    outline: none;
    vertical-align: middle;
    text-align: center;
    overflow: hidden;
}

span{
    font-size: 15px;
}


/* Media queries and hover effects */
@media (min-width: 400px) {
    .btn {
        width: auto;
    }
}

@media (min-width: 800px) {
    /*.btn {*/
    /*    font-size: 1.1rem;*/
    /*    padding: 1.6rem 2.8rem;*/
    /*}*/
}

.btn:hover {
    background: lighten(#1d1d1d, 5%);
    border-color: lighten(#1d1d1d, 5%);
    color: #fff;
}

.btn-border {
    background-color: transparent;
    color: #1d1d1d;
}

.btn-arrow {
    position: relative;
    transition: background-color 300ms ease-out;
}

.btn-arrow span {
    display: inline-block;
    position: relative;
    transition: all 300ms ease-out;
    will-change: transform;
}

.btn-arrow:hover span {
    transform: translate3d(-1rem, 0, 0);
}

.btn-arrow svg {
    position: absolute;
    width: 1.1em;
    right: 0px;
    right: 0rem;
    opacity: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: all 300ms ease-out;
    will-change: right, opacity;
}

.btn-arrow:hover svg {
    opacity: 1;
    right: -2rem;
}

/* Style adjustments for svg */
.btn-arrow svg * {
    stroke-width: 5;
    stroke-color: transparent;
}

@media (max-width: 480px) {
    .btn{
        width: 140px;
        height: 50px;
    }

    .hero-info h1 {
        font-size: 30px;
    }

    .hero-info p {
        font-size: 13px;
    }

    .hero-buttons {
        flex-direction: column;
        width: 100%;
        align-items: center;
    }
}
</style>
