<template>
    <ObserverComponent classToToggle="fadeInTop" playOnce="true" >
    <table class="container">
        <thead>
        <tr>

        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in data" :key="index">
            <td>{{ item.characteristic }}</td>
            <td>{{ item.value }}</td>
        </tr>
        </tbody>
    </table>
    </ObserverComponent>
</template>

<script>
import ObserverComponent from "@/components/IntersectionObserver.vue";

export default {
    name: 'ResponsiveTable',
    components: {ObserverComponent},
    data() {
        return {
            data: [
                { characteristic: 'Length', value: 'Up to 20 cm (not less than 4 cm)' },
                { characteristic: 'Thickness', value: 'Up to 5-6 cm (not less than 2 cm)' },
                { characteristic: 'Diameter', value: 'Between 2.5 to 10 cm' },
                { characteristic: 'Granulometry', value: 'Mixture from 5 to 25 cm' },
                { characteristic: 'Carbon', value: '6% - 10% max' },
                { characteristic: 'Humidity', value: '6.54% max' },
                { characteristic: 'Fixed Carbon', value: '75 - 82%' },
                { characteristic: 'Burning Time', value: '4 - 8 hours' },
                { characteristic: 'Incandescence Time', value: '4 - 5 hours' },
                { characteristic: 'Volatile Matters', value: '20% - 25% max' },
                { characteristic: 'Heating Power/Caloric Value', value: '6,500 Kcal/Kg' },
                { characteristic: 'No spark', value: 'low to none' },
                { characteristic: 'Ashes', value: 'low' },
                { characteristic: 'Flavor', value: 'Subtle 100% Natural' },
            ]
        };
    }

}
</script>

<style scoped>



.container td {
    font-weight: normal;
    font-size: 1em;
    -webkit-box-shadow: 0 2px 2px -2px #0E1119;
    -moz-box-shadow: 0 2px 2px -2px #0E1119;
    box-shadow: 0 2px 2px -2px #0E1119;
}

.container {
    text-align: left;
    overflow: hidden;
    width: 100%;
    height: 100%;
    margin: 0 auto;
}

.container td, .container th {
    padding-bottom: 2%;
    padding-top: 2%;
    padding-left:2%;
}

/* Background-color of the odd rows */
.container tr:nth-child(odd) {
    background-color: #131413;
}

/* Background-color of the even rows */
.container tr:nth-child(even) {
    background-color: #131413;
}

.container th {
    background-color: #1F2739;
}

.container td:first-child { color: goldenrod; }

.container tr:hover {
    background-color: #464A52;
    -webkit-box-shadow: 0 6px 6px -6px #0E1119;
    -moz-box-shadow: 0 6px 6px -6px #0E1119;
    box-shadow: 0 6px 6px -6px #0E1119;
}

.container td:hover {
    background-color: white;
    color: #403E10;
    font-weight: bold;

    box-shadow: #7F7C21 -1px 1px, #7F7C21 -2px 2px, #7F7C21 -3px 3px, #7F7C21 -4px 4px, #7F7C21 -5px 5px, #7F7C21 -6px 6px;
    transform: translate3d(6px, -6px, 0);

    transition-delay: 0s;
    transition-duration: 0.4s;
    transition-property: all;
    transition-timing-function: line;
}

@media (max-width: 800px) {
    .container td:nth-child(4),
    .container th:nth-child(4) { display: none; }
}
</style>
